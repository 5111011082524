export const liveTickers = [
    {
        title: 'ES',
        label: 'E-Mini S&P 500 Futures'
    },
    {
        title: 'YM',
        label: 'E-Mini DOW JONES Futures'
    },
    {
        title: 'ZC',
        label: 'CORN Futures'
    },
    {
        title: "ZN",
        label: 'T-Bills Futures'
    }
];

// const requiredLength = Math.ceil(liveTickers.length / 3) * 3;

// while (liveTickers.length < requiredLength) {
//     liveTickers.push({
//         title: "",
//         label: "",
//     });
// }

// module.exports = liveTickers;